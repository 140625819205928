import React from 'react'

const MeetUs: React.FC = () => {
  let loaded = false

  setTimeout(() => {
    if (typeof (Calendly) !== 'undefined' && !loaded) {
      loaded = true
      Calendly.initInlineWidget({
        url: 'https://calendly.com/elm-foods/elm-foods-introduction?hide_landing_page_details=1&hide_event_type_details=1',
        parentElement: document.getElementById('calendly-cal'),
        prefill: {},
        utm: {}
      });
    }
  }, 1000)

  return (
    <React.Fragment>
      <section>
        <div className="container py-3 my-5">
          <div className="row">
            <h2 className="font-weight-bold col-12 col-lg-8 mx-auto">We'd love to talk</h2>
            <p className="font-weight-light  col-12 col-lg-8 mx-auto">Interested in helping us build your dream grocery store? We'd love to hear from you!
            Take this quick <a href="https://elmfoods.typeform.com/to/YZeYeXLS" className="font-weight-bold">2 minute survey</a> or find 15 minutes with us below to tell us about what you want in your neighborhood grocery store.</p>
          </div>
          <div className="row">
            <div className="col-12 col-lg-8 mx-auto">
              <div id="calendly-cal" style={{ height: "700px" }} />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default MeetUs
