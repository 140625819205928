import * as React from 'react'
import { Link } from 'gatsby'
import MeetUs from '../components/homepage/MeetUs'

import IndexLayout from '../layouts'

const About = ({ data }) => {
  return (
    <IndexLayout>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 pt-5">
            <h1 className="font-weight-bold">About us</h1>
            <p className="font-weight-light">Three years ago I started looking for ways to lower my carbon footprint and found that one of the most impactful things I could do was reduce the amount of animal products in my diet. So I started a plant based meal company, delivering meals and selling them at LA farmers markets to help people eat less meat and dairy.</p>
            <p className="font-weight-light">Fast forward to today and we're opening a store to make shopping for and preparing plant based meals easy and convenient. While we work on opening our doors, <Link to="/shop-all-recipes"><u>try one of our recipes</u></Link> and we’ll deliver the ingredients to you! We can’t wait to hear what you think.</p>
            <p>- Joel & Melissa</p>
          </div>
          <div className="col-12 col-md-6 pt-5">
            <img className="img-fluid bowl" alt="Mushroom Umami" src={data.melissaAndJoelImage.childImageSharp.fixed.src} />
          </div>
        </div>
      </div>
      <MeetUs />
    </IndexLayout>
  )
}

export const query = graphql`
  query AboutImageQuery {
    melissaAndJoelImage: file(relativePath: { eq: "images/M+J.png" }) {
      size
      childImageSharp {
        fixed(width: 680, height: 880) {
          src
        }
      }
    }
  }
`

export default About
